import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UiService } from '../admin/service/ui.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService{
  emitScroll = new EventEmitter();
  public lastY = 0;
  public routeObs = new Subscription();
  private _showMulti = false;

  set showMulti(value){
    this._showMulti = value;
  }

  get showMulti(){
    return this._showMulti;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private platform: Platform,
    private socialSharing: SocialSharing,
    private uiSv: UiService
  ) {}

  contentScroll(ev){
    this.emitScroll.emit(ev);
  }

  
  subscribeData(){
    // const obj = {
    //   name: 'Este es un producto',
    //   image: 'https://load.neuronweb.online/file/product/1671120323936.png',
    //   description: 'Esta es una descripcion'
    // }
    // this.updateMetadata(obj);

    const routerObs = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary')
    ).subscribe(() => {
      const pathname = window.location.pathname;
      const routeObs = this.route.data.subscribe(() => {
        if(
          pathname.includes('product-detail') 
          || pathname.includes('market-place/search')
          || pathname.includes('market-place/store')
          || pathname.includes('market-place/cart')
        ){
          this.showMulti = true; 
        }else{
          this.showMulti = false;
        }
      })
      this.routeObs.add(routeObs);
    })

    this.routeObs.add(routerObs);
  }

  ngOnDestroy(): void {
    this.routeObs.unsubscribe();
  }

  async share(message, href){
    if(this.platform.is('capacitor')){
      // const text = `${this.data?.name} 
      // ${this.data.price}
      // ${this.data?.description}`;
      // const text = this.trSv.instant('VER_MAS');
      this.socialSharing.share(message, 'Afiliarme', null, href);
    }else{
      // const seeMore = this.trSv.instant('VER_MAS');
      const data: ShareData = {
        url: href,
        title: message,
        text: 'Afiliarme'
      }
      const nav = navigator;
      if('share' in navigator){
        await navigator.share(data)
      }else{
        nav.clipboard.writeText(href);
        await this.uiSv.showToast('El enlace se ha copiado al portapapeles.');
      }
    }
  }
  
}
