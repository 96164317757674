import { Injectable } from '@angular/core';

import { NavController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastController: ToastController,
    private translateSv: TranslateService,
    private navCtrl: NavController
  ) {}
  toast: HTMLIonToastElement;
  propsPromo;

  async show(message?: string, text?) {
    message = this.translateSv.instant(message)
    if(this.toast){
      await this.toast.dismiss();
    }
    this.toast = await this.toastController.create({
      message: message ?? 'Su petición ha sido exitosa',
      color: 'primary',
      duration: 4000,
      htmlAttributes: { tabindex: undefined },
      buttons: this.propsPromo ? [
          {
            side: 'end',
            text,
            handler: () => {
              this.seeNotify();
            }
          },
      ] : []
    });
    await this.toast.present();
  }

  async error(message?: any[]) {
    let newMessage = '';
    if (message) {
      message.map((msj) => {
        newMessage += msj.message + '\n';
      });
    }
    const toast = await this.toastController.create({
      message: newMessage
        ? newMessage
        : 'Ha ocurrido un error inesperado\nPor favor vuela a intentarlo',
      color: 'primary',
      duration: 4000,
    });
    toast.present();
  }

  seeNotify(){
    this.navCtrl.navigateForward(this.propsPromo.url)
  }

  
}
