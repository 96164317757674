/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */

/* Angular */
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

/* Ionic */
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

/* Translate */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GeneralesInterceptor } from './generales/services/interceptors/interceptor.service';
import { LayoutModule } from '@angular/cdk/layout';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';


import { ServiceWorkerModule } from '@angular/service-worker';
import { InitPushNotificationService } from './generales/services/init-push-notification.service';
import { ReactiveFormsModule } from '@angular/forms';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { NgxPayPalModule } from 'ngx-paypal';

import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
// import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

registerLocaleData(localeEs, 'es');
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        NgxPayPalModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ReactiveFormsModule,
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        LayoutModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFireMessagingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        InitPushNotificationService,
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GeneralesInterceptor,
            multi: true,
        },
        GooglePlus,
        FCM,
        SocialSharing
        // SocialSharing
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
