import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AdminServiceService } from 'src/app/admin/service/admin-service.service';
import { BolsaService } from './bolsa.service';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class PaisService {
  /* Siglas según la Lista de códigos de idioma ISO 3166-1 */
  country: any = '/assets/img/flags/venezuela.svg';
  
  selectedCountry = {
    country: 'Venezuela',
    code: 've',
    prefPhone: '+58',
    img: './assets/img/flags/venezuela.svg',
  }

  countrys = [
    // {
    //   country: 'Venezuela',
    //   code: 've',
    //   prefPhone: '+58',
    //   img: './assets/img/flags/venezuela.svg',
    // }
  ];

  public labels = [
    {value: 0, name: 'Enero'}, 
    {value: 1, name: 'Febrero'}, 
    {value: 2, name: 'Marzo'}, 
    {value: 3, name: 'Abril'}, 
    {value: 4, name: 'Mayo'}, 
    {value: 5, name: 'Junio'}, 
    {value: 6, name: 'Julio'}, 
    {value: 7, name: 'Agosto'}, 
    {value: 8, name: 'Septiembre'}, 
    {value: 9, name: 'Octubre'}, 
    {value: 10, name: 'Noviembre'}, 
    {value: 11, name: 'Diciembre'}
  ];

  constructor(
    private storageService: StorageService,
    private adminSv: AdminServiceService,
  ) {
    this.getCodes();
  }

  getCodes(){
    this.adminSv.getAllCountry(null, null, 'index').pipe(
      map((data: any) => {
        let countries: any[] = data.data.data;

        countries = countries.map(country => {
          return {
            country: country.name,
            prefPhone: `${country.code}`,
            code: `${country.iso}`,
            id: country.id
          }
        }, [])

        return countries
      })
    ).subscribe(res => {
      this.countrys = res;
    })
  }

  async initCountry() {
    let country = await this.storageService.get('country');
    await this.changeCountry(country);
  }

  async changeCountry(country: string) {
    await this.storageService.set('country', country);
    this.country = await this.selectCountry(country);
  }

  async selectCountry(country: string) {
    return this.countrys.filter((element) => element.code === country)[0];
  }

  comprobeCode(number){

    if(number){
      let data = [];
      for (const country of this.countrys) {
        const prefPhone = country.prefPhone;
        const slicedNum = `+${number.slice(0, prefPhone.length -1 )}`;
        if(prefPhone === slicedNum){
          const numberFixed = number.slice(prefPhone.length - 1);
          data = [prefPhone, numberFixed];
          break;
        }
      }
      return data;
    }
  }
}
