import { Injectable } from '@angular/core';
import { GoogleAuthProvider, getAuth, FacebookAuthProvider, OAuthProvider, getRedirectResult, signInWithRedirect, signInWithCredential, onAuthStateChanged  } from "firebase/auth";
import { initializeApp }from "@firebase/app"
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';
import { InitPushNotificationService } from '../../services/init-push-notification.service';
import { StorageService } from '../../services/storage.service';
import { LoadingService } from '../../services/loading.service';
import { Platform } from '@ionic/angular';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {
  googleProvider = new GoogleAuthProvider();
  facebookProvider = new FacebookAuthProvider();
  appleProvider = new OAuthProvider('apple.com')
  auth = getAuth(initializeApp(environment.firebaseConfig));

  constructor( 
    private loginService:LoginService,
    private initPushService: InitPushNotificationService,
    private storage:StorageService,
    private loadService: LoadingService,
    private platform: Platform,
    private googlePlus: GooglePlus
  ) {
    if(!this.platform.is('android') && !this.platform.is('ios')){
      const login = localStorage.getItem('loginIn');
      if(login){
        this.loadService.show('Iniciando Sesion...')
      }
  
      getRedirectResult(this.auth).then(async res => {    
        await this.execLogin(res)  
      }).catch(err => {
        console.error(err);
        this.loadService.hide();
        localStorage.removeItem('loginIn');
      })
    }
  }
  
  async execLogin(res){
    this.loadService.hide();
    localStorage.removeItem('loginIn');
    if(!res) return
    const { displayName, emailVerified, email, uid, providerId } = res.user;
    const tokenPush = this.initPushService.tokenUser;
    const country = await this.storage.get('country');
    if(tokenPush) this.storage.set('tokenPush', tokenPush);
    const data = {
      displayName, 
      emailVerified, 
      email, 
      uid, 
      providerId, 
      tokenPush,
      country,
      receiveNotis: tokenPush ? true : false
    }
    this.loginService.login(data);
  }

  async loginGoogle(){
    localStorage.setItem('loginIn', '1');
    if(this.platform.is('android')){
      this.loginGoogleAndroid()
    }else{
      await signInWithRedirect(this.auth, this.googleProvider).then();
    }
  }

  async loginGoogleAndroid(){
    try{
      this.googlePlus.login({
        'webClientId': '170100981890-hc9r58t4imiipald5lnkg0pq1osj6r07.apps.googleusercontent.com',
        'offline': true
      }).then(async (res) => {
        const { idToken, accessToken } = res;
        this.onLoginSuccess(idToken, accessToken);
      }).catch(err => {
        console.error(err);
      })
    }catch(err){
      console.error(err);
    }
  }

  onLoginSuccess(accessToken, accessSecret) {
    const credential = accessSecret ? GoogleAuthProvider
        .credential(accessToken, accessSecret) : GoogleAuthProvider
            .credential(accessToken);
    signInWithCredential(this.auth, credential)
      .then((success) => {
        this.execLogin(success)
      });

  }

  loginApple(){
    localStorage.setItem('loginIn', '1');
    signInWithRedirect(this.auth, this.appleProvider).then();
  }

  loginFacebook(){
    localStorage.setItem('loginIn', '1');
    signInWithRedirect(this.auth, this.facebookProvider).then();
  }

  async signOutApp(){
    await this.auth.signOut()
    return 'Signed out';
  }
}
